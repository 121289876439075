import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type ComponentPromotionComponentPromotion = {
  __typename?: 'ComponentPromotionComponentPromotion';
  description: Scalars['String'];
  id: Scalars['ID'];
  image: UploadFileEntityResponse;
};

export type ComponentPromotionComponentPromotionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPromotionComponentPromotionFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPromotionComponentPromotionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPromotionComponentPromotionFiltersInput>>>;
};

export type ComponentPromotionComponentPromotionInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['ID']>;
};

export type ComponentServiceComponentFeature = {
  __typename?: 'ComponentServiceComponentFeature';
  description: Scalars['String'];
  icon: UploadFileEntityResponse;
  id: Scalars['ID'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type ComponentServiceComponentFeatureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentServiceComponentFeatureFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentServiceComponentFeatureFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentServiceComponentFeatureFiltersInput>>>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentServiceComponentFeatureInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ComponentServiceComponentProcess = {
  __typename?: 'ComponentServiceComponentProcess';
  content?: Maybe<Array<Maybe<ComponentServiceComponentProcessContent>>>;
  id: Scalars['ID'];
  image: UploadFileEntityResponse;
};


export type ComponentServiceComponentProcessContentArgs = {
  filters?: InputMaybe<ComponentServiceComponentProcessContentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentServiceComponentProcessContent = {
  __typename?: 'ComponentServiceComponentProcessContent';
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ComponentServiceComponentProcessContentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentServiceComponentProcessContentFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentServiceComponentProcessContentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentServiceComponentProcessContentFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentServiceComponentProcessContentInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ComponentServiceComponentProcessFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentServiceComponentProcessFiltersInput>>>;
  content?: InputMaybe<ComponentServiceComponentProcessContentFiltersInput>;
  not?: InputMaybe<ComponentServiceComponentProcessFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentServiceComponentProcessFiltersInput>>>;
};

export type ComponentServiceComponentProcessInput = {
  content?: InputMaybe<Array<InputMaybe<ComponentServiceComponentProcessContentInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['ID']>;
};

export type ComponentServiceComponentTerm = {
  __typename?: 'ComponentServiceComponentTerm';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type ComponentServiceComponentTermFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentServiceComponentTermFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentServiceComponentTermFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentServiceComponentTermFiltersInput>>>;
};

export type ComponentServiceComponentTermInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = ComponentPromotionComponentPromotion | ComponentServiceComponentFeature | ComponentServiceComponentProcess | ComponentServiceComponentProcessContent | ComponentServiceComponentTerm | I18NLocale | Promotion | Service | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createPromotion?: Maybe<PromotionEntityResponse>;
  createService?: Maybe<ServiceEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deletePromotion?: Maybe<PromotionEntityResponse>;
  deleteService?: Maybe<ServiceEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateFileInfo: UploadFileEntityResponse;
  updatePromotion?: Maybe<PromotionEntityResponse>;
  updateService?: Maybe<ServiceEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreatePromotionArgs = {
  data: PromotionInput;
};


export type MutationCreateServiceArgs = {
  data: ServiceInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeletePromotionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdatePromotionArgs = {
  data: PromotionInput;
  id: Scalars['ID'];
};


export type MutationUpdateServiceArgs = {
  data: ServiceInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  createdAt?: Maybe<Scalars['DateTime']>;
  promotion?: Maybe<Array<Maybe<ComponentPromotionComponentPromotion>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PromotionPromotionArgs = {
  filters?: InputMaybe<ComponentPromotionComponentPromotionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PromotionEntity = {
  __typename?: 'PromotionEntity';
  attributes?: Maybe<Promotion>;
  id?: Maybe<Scalars['ID']>;
};

export type PromotionEntityResponse = {
  __typename?: 'PromotionEntityResponse';
  data?: Maybe<PromotionEntity>;
};

export type PromotionEntityResponseCollection = {
  __typename?: 'PromotionEntityResponseCollection';
  data: Array<PromotionEntity>;
  meta: ResponseCollectionMeta;
};

export type PromotionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PromotionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<PromotionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PromotionFiltersInput>>>;
  promotion?: InputMaybe<ComponentPromotionComponentPromotionFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PromotionInput = {
  promotion?: InputMaybe<Array<InputMaybe<ComponentPromotionComponentPromotionInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  promotion?: Maybe<PromotionEntityResponse>;
  promotions?: Maybe<PromotionEntityResponseCollection>;
  service?: Maybe<ServiceEntityResponse>;
  services?: Maybe<ServiceEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPromotionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPromotionsArgs = {
  filters?: InputMaybe<PromotionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryServiceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServicesArgs = {
  filters?: InputMaybe<ServiceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type Service = {
  __typename?: 'Service';
  cover: UploadFileEntityResponse;
  createdAt?: Maybe<Scalars['DateTime']>;
  feature: Array<Maybe<ComponentServiceComponentFeature>>;
  line_url?: Maybe<Scalars['String']>;
  process: Array<Maybe<ComponentServiceComponentProcess>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  review_url?: Maybe<Scalars['String']>;
  route: Scalars['String'];
  shop_url?: Maybe<Scalars['String']>;
  subtitle: Scalars['String'];
  term: ComponentServiceComponentTerm;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  youtube_url?: Maybe<Scalars['String']>;
};


export type ServiceFeatureArgs = {
  filters?: InputMaybe<ComponentServiceComponentFeatureFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceProcessArgs = {
  filters?: InputMaybe<ComponentServiceComponentProcessFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ServiceEntity = {
  __typename?: 'ServiceEntity';
  attributes?: Maybe<Service>;
  id?: Maybe<Scalars['ID']>;
};

export type ServiceEntityResponse = {
  __typename?: 'ServiceEntityResponse';
  data?: Maybe<ServiceEntity>;
};

export type ServiceEntityResponseCollection = {
  __typename?: 'ServiceEntityResponseCollection';
  data: Array<ServiceEntity>;
  meta: ResponseCollectionMeta;
};

export type ServiceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ServiceFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  feature?: InputMaybe<ComponentServiceComponentFeatureFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  line_url?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ServiceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ServiceFiltersInput>>>;
  process?: InputMaybe<ComponentServiceComponentProcessFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  review_url?: InputMaybe<StringFilterInput>;
  route?: InputMaybe<StringFilterInput>;
  shop_url?: InputMaybe<StringFilterInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  term?: InputMaybe<ComponentServiceComponentTermFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  youtube_url?: InputMaybe<StringFilterInput>;
};

export type ServiceInput = {
  cover?: InputMaybe<Scalars['ID']>;
  feature?: InputMaybe<Array<InputMaybe<ComponentServiceComponentFeatureInput>>>;
  line_url?: InputMaybe<Scalars['String']>;
  process?: InputMaybe<Array<InputMaybe<ComponentServiceComponentProcessInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  review_url?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
  shop_url?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<ComponentServiceComponentTermInput>;
  title?: InputMaybe<Scalars['String']>;
  youtube_url?: InputMaybe<Scalars['String']>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type GetBigCleaningServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBigCleaningServiceQuery = { __typename?: 'Query', services?: { __typename?: 'ServiceEntityResponseCollection', data: Array<{ __typename?: 'ServiceEntity', attributes?: { __typename?: 'Service', title: string, subtitle: string, line_url?: string | null, shop_url?: string | null, youtube_url?: string | null, review_url?: string | null, cover: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, feature: Array<{ __typename?: 'ComponentServiceComponentFeature', title: string, subtitle: string, description: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null>, process: Array<{ __typename?: 'ComponentServiceComponentProcess', id: string, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, content?: Array<{ __typename?: 'ComponentServiceComponentProcessContent', title: string, description: string } | null> | null } | null>, term: { __typename?: 'ComponentServiceComponentTerm', description: string } } | null }> } | null };

export type GetDisinfectionOfAirConditionerServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDisinfectionOfAirConditionerServiceQuery = { __typename?: 'Query', services?: { __typename?: 'ServiceEntityResponseCollection', data: Array<{ __typename?: 'ServiceEntity', attributes?: { __typename?: 'Service', title: string, subtitle: string, line_url?: string | null, shop_url?: string | null, youtube_url?: string | null, review_url?: string | null, cover: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, feature: Array<{ __typename?: 'ComponentServiceComponentFeature', title: string, subtitle: string, description: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null>, process: Array<{ __typename?: 'ComponentServiceComponentProcess', id: string, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, content?: Array<{ __typename?: 'ComponentServiceComponentProcessContent', title: string, description: string } | null> | null } | null>, term: { __typename?: 'ComponentServiceComponentTerm', description: string } } | null }> } | null };

export type GetGenuineLeatherRepairServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenuineLeatherRepairServiceQuery = { __typename?: 'Query', services?: { __typename?: 'ServiceEntityResponseCollection', data: Array<{ __typename?: 'ServiceEntity', attributes?: { __typename?: 'Service', title: string, subtitle: string, line_url?: string | null, shop_url?: string | null, youtube_url?: string | null, review_url?: string | null, cover: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, feature: Array<{ __typename?: 'ComponentServiceComponentFeature', title: string, subtitle: string, description: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null>, process: Array<{ __typename?: 'ComponentServiceComponentProcess', id: string, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, content?: Array<{ __typename?: 'ComponentServiceComponentProcessContent', title: string, description: string } | null> | null } | null>, term: { __typename?: 'ComponentServiceComponentTerm', description: string } } | null }> } | null };

export type GetMovingServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMovingServiceQuery = { __typename?: 'Query', services?: { __typename?: 'ServiceEntityResponseCollection', data: Array<{ __typename?: 'ServiceEntity', attributes?: { __typename?: 'Service', title: string, subtitle: string, line_url?: string | null, shop_url?: string | null, youtube_url?: string | null, review_url?: string | null, cover: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, feature: Array<{ __typename?: 'ComponentServiceComponentFeature', title: string, subtitle: string, description: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null>, process: Array<{ __typename?: 'ComponentServiceComponentProcess', id: string, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, content?: Array<{ __typename?: 'ComponentServiceComponentProcessContent', title: string, description: string } | null> | null } | null>, term: { __typename?: 'ComponentServiceComponentTerm', description: string } } | null }> } | null };

export type GetPreHandoverInspectionServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPreHandoverInspectionServiceQuery = { __typename?: 'Query', services?: { __typename?: 'ServiceEntityResponseCollection', data: Array<{ __typename?: 'ServiceEntity', attributes?: { __typename?: 'Service', title: string, subtitle: string, line_url?: string | null, shop_url?: string | null, youtube_url?: string | null, review_url?: string | null, cover: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, feature: Array<{ __typename?: 'ComponentServiceComponentFeature', title: string, subtitle: string, description: string, icon: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null>, process: Array<{ __typename?: 'ComponentServiceComponentProcess', id: string, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null }, content?: Array<{ __typename?: 'ComponentServiceComponentProcessContent', title: string, description: string } | null> | null } | null>, term: { __typename?: 'ComponentServiceComponentTerm', description: string } } | null }> } | null };

export type GetPromotionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPromotionQuery = { __typename?: 'Query', promotions?: { __typename?: 'PromotionEntityResponseCollection', data: Array<{ __typename?: 'PromotionEntity', attributes?: { __typename?: 'Promotion', title: string, subtitle: string, promotion?: Array<{ __typename?: 'ComponentPromotionComponentPromotion', description: string, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null> | null } | null }> } | null };


export const GetBigCleaningServiceDocument = gql`
    query GetBigCleaningService {
  services(filters: {route: {eq: "BigCleaningService"}}, pagination: {limit: 1}) {
    data {
      attributes {
        title
        subtitle
        cover {
          data {
            attributes {
              url
            }
          }
        }
        line_url
        shop_url
        youtube_url
        review_url
        feature {
          icon {
            data {
              attributes {
                url
              }
            }
          }
          title
          subtitle
          description
        }
        process {
          id
          image {
            data {
              attributes {
                url
              }
            }
          }
          content {
            title
            description
          }
        }
        term {
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetBigCleaningServiceQuery__
 *
 * To run a query within a React component, call `useGetBigCleaningServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBigCleaningServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBigCleaningServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBigCleaningServiceQuery(baseOptions?: Apollo.QueryHookOptions<GetBigCleaningServiceQuery, GetBigCleaningServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBigCleaningServiceQuery, GetBigCleaningServiceQueryVariables>(GetBigCleaningServiceDocument, options);
      }
export function useGetBigCleaningServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBigCleaningServiceQuery, GetBigCleaningServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBigCleaningServiceQuery, GetBigCleaningServiceQueryVariables>(GetBigCleaningServiceDocument, options);
        }
export type GetBigCleaningServiceQueryHookResult = ReturnType<typeof useGetBigCleaningServiceQuery>;
export type GetBigCleaningServiceLazyQueryHookResult = ReturnType<typeof useGetBigCleaningServiceLazyQuery>;
export type GetBigCleaningServiceQueryResult = Apollo.QueryResult<GetBigCleaningServiceQuery, GetBigCleaningServiceQueryVariables>;
export const GetDisinfectionOfAirConditionerServiceDocument = gql`
    query GetDisinfectionOfAirConditionerService {
  services(filters: {route: {eq: "DisinfectionOfAirConditionerService"}}, pagination: {limit: 1}) {
    data {
      attributes {
        title
        subtitle
        cover {
          data {
            attributes {
              url
            }
          }
        }
        line_url
        shop_url
        youtube_url
        review_url
        feature {
          icon {
            data {
              attributes {
                url
              }
            }
          }
          title
          subtitle
          description
        }
        process {
          id
          image {
            data {
              attributes {
                url
              }
            }
          }
          content {
            title
            description
          }
        }
        term {
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetDisinfectionOfAirConditionerServiceQuery__
 *
 * To run a query within a React component, call `useGetDisinfectionOfAirConditionerServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisinfectionOfAirConditionerServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisinfectionOfAirConditionerServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDisinfectionOfAirConditionerServiceQuery(baseOptions?: Apollo.QueryHookOptions<GetDisinfectionOfAirConditionerServiceQuery, GetDisinfectionOfAirConditionerServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisinfectionOfAirConditionerServiceQuery, GetDisinfectionOfAirConditionerServiceQueryVariables>(GetDisinfectionOfAirConditionerServiceDocument, options);
      }
export function useGetDisinfectionOfAirConditionerServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisinfectionOfAirConditionerServiceQuery, GetDisinfectionOfAirConditionerServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisinfectionOfAirConditionerServiceQuery, GetDisinfectionOfAirConditionerServiceQueryVariables>(GetDisinfectionOfAirConditionerServiceDocument, options);
        }
export type GetDisinfectionOfAirConditionerServiceQueryHookResult = ReturnType<typeof useGetDisinfectionOfAirConditionerServiceQuery>;
export type GetDisinfectionOfAirConditionerServiceLazyQueryHookResult = ReturnType<typeof useGetDisinfectionOfAirConditionerServiceLazyQuery>;
export type GetDisinfectionOfAirConditionerServiceQueryResult = Apollo.QueryResult<GetDisinfectionOfAirConditionerServiceQuery, GetDisinfectionOfAirConditionerServiceQueryVariables>;
export const GetGenuineLeatherRepairServiceDocument = gql`
    query GetGenuineLeatherRepairService {
  services(filters: {route: {eq: "GenuineLeatherRepairService"}}, pagination: {limit: 1}) {
    data {
      attributes {
        title
        subtitle
        cover {
          data {
            attributes {
              url
            }
          }
        }
        line_url
        shop_url
        youtube_url
        review_url
        feature {
          icon {
            data {
              attributes {
                url
              }
            }
          }
          title
          subtitle
          description
        }
        process {
          id
          image {
            data {
              attributes {
                url
              }
            }
          }
          content {
            title
            description
          }
        }
        term {
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetGenuineLeatherRepairServiceQuery__
 *
 * To run a query within a React component, call `useGetGenuineLeatherRepairServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenuineLeatherRepairServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenuineLeatherRepairServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenuineLeatherRepairServiceQuery(baseOptions?: Apollo.QueryHookOptions<GetGenuineLeatherRepairServiceQuery, GetGenuineLeatherRepairServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenuineLeatherRepairServiceQuery, GetGenuineLeatherRepairServiceQueryVariables>(GetGenuineLeatherRepairServiceDocument, options);
      }
export function useGetGenuineLeatherRepairServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenuineLeatherRepairServiceQuery, GetGenuineLeatherRepairServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenuineLeatherRepairServiceQuery, GetGenuineLeatherRepairServiceQueryVariables>(GetGenuineLeatherRepairServiceDocument, options);
        }
export type GetGenuineLeatherRepairServiceQueryHookResult = ReturnType<typeof useGetGenuineLeatherRepairServiceQuery>;
export type GetGenuineLeatherRepairServiceLazyQueryHookResult = ReturnType<typeof useGetGenuineLeatherRepairServiceLazyQuery>;
export type GetGenuineLeatherRepairServiceQueryResult = Apollo.QueryResult<GetGenuineLeatherRepairServiceQuery, GetGenuineLeatherRepairServiceQueryVariables>;
export const GetMovingServiceDocument = gql`
    query GetMovingService {
  services(filters: {route: {eq: "MovingService"}}, pagination: {limit: 1}) {
    data {
      attributes {
        title
        subtitle
        cover {
          data {
            attributes {
              url
            }
          }
        }
        line_url
        shop_url
        youtube_url
        review_url
        feature {
          icon {
            data {
              attributes {
                url
              }
            }
          }
          title
          subtitle
          description
        }
        process {
          id
          image {
            data {
              attributes {
                url
              }
            }
          }
          content {
            title
            description
          }
        }
        term {
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetMovingServiceQuery__
 *
 * To run a query within a React component, call `useGetMovingServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMovingServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMovingServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMovingServiceQuery(baseOptions?: Apollo.QueryHookOptions<GetMovingServiceQuery, GetMovingServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMovingServiceQuery, GetMovingServiceQueryVariables>(GetMovingServiceDocument, options);
      }
export function useGetMovingServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMovingServiceQuery, GetMovingServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMovingServiceQuery, GetMovingServiceQueryVariables>(GetMovingServiceDocument, options);
        }
export type GetMovingServiceQueryHookResult = ReturnType<typeof useGetMovingServiceQuery>;
export type GetMovingServiceLazyQueryHookResult = ReturnType<typeof useGetMovingServiceLazyQuery>;
export type GetMovingServiceQueryResult = Apollo.QueryResult<GetMovingServiceQuery, GetMovingServiceQueryVariables>;
export const GetPreHandoverInspectionServiceDocument = gql`
    query GetPreHandoverInspectionService {
  services(filters: {route: {eq: "PreHandoverInspectionService"}}, pagination: {limit: 1}) {
    data {
      attributes {
        title
        subtitle
        cover {
          data {
            attributes {
              url
            }
          }
        }
        line_url
        shop_url
        youtube_url
        review_url
        feature {
          icon {
            data {
              attributes {
                url
              }
            }
          }
          title
          subtitle
          description
        }
        process {
          id
          image {
            data {
              attributes {
                url
              }
            }
          }
          content {
            title
            description
          }
        }
        term {
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetPreHandoverInspectionServiceQuery__
 *
 * To run a query within a React component, call `useGetPreHandoverInspectionServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreHandoverInspectionServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreHandoverInspectionServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPreHandoverInspectionServiceQuery(baseOptions?: Apollo.QueryHookOptions<GetPreHandoverInspectionServiceQuery, GetPreHandoverInspectionServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreHandoverInspectionServiceQuery, GetPreHandoverInspectionServiceQueryVariables>(GetPreHandoverInspectionServiceDocument, options);
      }
export function useGetPreHandoverInspectionServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreHandoverInspectionServiceQuery, GetPreHandoverInspectionServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreHandoverInspectionServiceQuery, GetPreHandoverInspectionServiceQueryVariables>(GetPreHandoverInspectionServiceDocument, options);
        }
export type GetPreHandoverInspectionServiceQueryHookResult = ReturnType<typeof useGetPreHandoverInspectionServiceQuery>;
export type GetPreHandoverInspectionServiceLazyQueryHookResult = ReturnType<typeof useGetPreHandoverInspectionServiceLazyQuery>;
export type GetPreHandoverInspectionServiceQueryResult = Apollo.QueryResult<GetPreHandoverInspectionServiceQuery, GetPreHandoverInspectionServiceQueryVariables>;
export const GetPromotionDocument = gql`
    query GetPromotion {
  promotions {
    data {
      attributes {
        title
        subtitle
        promotion {
          image {
            data {
              attributes {
                url
              }
            }
          }
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetPromotionQuery__
 *
 * To run a query within a React component, call `useGetPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPromotionQuery(baseOptions?: Apollo.QueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
      }
export function useGetPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
        }
export type GetPromotionQueryHookResult = ReturnType<typeof useGetPromotionQuery>;
export type GetPromotionLazyQueryHookResult = ReturnType<typeof useGetPromotionLazyQuery>;
export type GetPromotionQueryResult = Apollo.QueryResult<GetPromotionQuery, GetPromotionQueryVariables>;