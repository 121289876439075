import React, { ReactElement, FC } from "react";
import { Box, Typography, Grid, Button, Link } from "@mui/material";

const Contact: FC<any> = (): ReactElement => {
  function Iframe(props: any) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }
  const iframe =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.7802893605885!2d100.44272301526117!3d13.671119690399681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2a2b5ddff4fe3%3A0x97f7895d1b20d511!2sIndex%20Living%20Mall%20Head%20Office!5e0!3m2!1sth!2sth!4v1617184718659!5m2!1sth!2sth" width="100%" height="485" style="border:0;" allowfullscreen="" loading="lazy"></iframe>';

  return (
    <Box
      sx={{
        marginTop: "100px",
      }}
    >
      <Grid spacing={6} justifyContent="center">
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 900,
              textAlign: "center",
              fontSize: { xs: "28px", md: "32px" },
            }}
          >
            ติดต่อเรา
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                fontSize: { xs: "14px", md: "18px" },
              }}
            >
              CONTACT US
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      &nbsp;
      <Grid
        sx={{ flexGrow: { xs: 1, md: 2 } }}
        container
        justifyContent="center"
        spacing={{ xs: 8, md: 6 }}
      >
        <Grid item xs={10} md={3.5}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 900,
              textAlign: "left",
              color: "#FDC300",
              fontSize: { xs: "16px", md: "20px" },
            }}
          >
            บริษัท อินเด็กซ์ ลิฟวิ่งมอลล์ จำกัด (มหาชน) สำนักงานใหญ่
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ fontWeight: 700, textAlign: "left" }}
          >
            {" "}
            เลขที่ 147 ซอยพระรามที่ 2 ซอย 50 ถนนพระรามที่ 2 แขวงแสมดำ
            เขตบางขุนเทียน กรุงเทพฯ 10150{" "}
          </Typography>
          &nbsp;
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ textAlign: "left" }}
          >
            INDEX LIVING MALL PLC. Head Office 147 Soi Rama 2 Soi 50, Rama 2
            Road Samaedam, Bangkhunthian, Bangkok 10150
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 900,
              textAlign: "left",
              color: "#FDC300",
              fontSize: { xs: "24px", md: "28px" },
            }}
          >
            Call Center 1379 กด 2
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ fontWeight: 700, textAlign: "left" }}
          >
            วันทำการ : วันจันทร์ - วันอาทิตย์ (ยกเว้นวันหยุดนักขัตฤกษ์){" "}
            <br></br>
            เวลาทำการ : 09.00 - 18.00 น.
          </Typography>
          &nbsp;
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ textAlign: "left" }}
          >
            Working days : Monday - Sunday (except public holidays) <br></br>
            Business hours : 09.00 a.m. - 6.00 p.m.
          </Typography>
          &nbsp;
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ textAlign: "left" }}
          >
            Facebook : @indexhomeservice&nbsp;<br></br>
            LINE Official Account : @indexservice&nbsp;<br></br>
            Official Website : www.indexlivingmall.com
          </Typography>
          <br></br>
          {/* <Link href="https://homeservice-register.indexlivingmall.com/" target="_blank" sx={{ textDecoration: 'none' }}> <Button variant="contained" sx={{ fontSize: { md: '16px' }, fontWeight: 900, background: '#FDC300', borderRadius: '90px', color: 'white', position: 'absolute', left: { xs: '10%', md: '10%' }, right: { xs: '10%', md: '65%' } }}><img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Vector-2.png" width="23" height="23" alt="Vector-2"></img>&nbsp;REGISTER รับบริการ</Button></Link> */}
        </Grid>

        <Grid item xs={12} md={6}>
          {/* <GoogleMaps /> */}
          <Iframe iframe={iframe} />
        </Grid>
      </Grid>
      &nbsp;<br></br>
      <br></br>
      <Grid
        sx={{ flexGrow: { xs: 1, md: 2 } }}
        container
        justifyContent="center"
        spacing={{ xs: 2, md: 2 }}
      >
        <Grid item xs={3} md={1} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2" component="div">
            <Link
              href="https://www.youtube.com/channel/UCvDVEfepTpVfVEsSPrU0rgg"
              target="_blank"
            >
              {" "}
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Youtube.png"
                alt="youtube"
                width="40px"
                height="40px"
              />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={3} md={1} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2" component="div">
            <Link
              href="https://www.facebook.com/indexhomeservice/"
              target="_blank"
            >
              {" "}
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/FB.png"
                alt="FB"
                width="40px"
                height="40px"
              />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={3} md={1} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2" component="div">
            <Link href="https://lin.ee/gEsXq0z" target="_blank">
              {" "}
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/LINE.png"
                alt="line-brands1"
                width="40px"
                height="40px"
              />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={3} md={1} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2" component="div">
            <Link
              href="https://www.indexlivingmall.com/store-location"
              target="_blank"
            >
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Location.png"
                alt="Location"
                width="40pxp"
                height="40px"
              />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ width: { xs: "90%", md: "60" }, height: "auto" }}
          >
            &nbsp; &nbsp; &nbsp;{" "}
            <Link href="https://www.indexlivingmall.com/" target="_blank">
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Indexlivingmall+Logo.png"
                alt="Indexlivingmall+Logo"
              />
            </Link>
          </Typography>
        </Grid>
      </Grid>
      &nbsp;
    </Box>
  );
};

export default Contact;
