import React, { ReactElement, FC } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Pagination,
  Stack,
  Link,
  ImageList,
  ImageListItem,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";

const Promotion: FC<any> = (): ReactElement => {
  const { search } = useLocation();
  let number = new URLSearchParams(search).get("number");

  const [page, setPage] = React.useState(1);
  const [countnumber, setcountnumber] = React.useState(0);

  if (number && countnumber === 0) {
    setPage(Number(number));
    setcountnumber(1);
  }

  let pages;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  switch (page) {
    case 1:
      pages = (
        <Grid
          sx={{ flexGrow: { xs: 1, md: 2 } }}
          container
          justifyContent="center"
          spacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6} sx={{ marginLeft: { md: "-70px" } }}>
            <ImageList
              sx={{
                width: { xs: "90%", md: "80%" },
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              <ImageListItem>
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1200x1200px+2022.10.06+Promotion.png"
                  alt="Promotion"
                />
              </ImageListItem>
            </ImageList>
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  color: "#FDC300",
                  fontSize: { xs: "20px", md: "22px" },
                }}
              >
                รับสิทธิพิเศษ 2 ต่อ เมื่อใช้บริการขนย้าย
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: 700, fontSize: { xs: "16px", md: "18px" } }}
              >
                {" "}
                ต่อที่ 1 สมาชิก Joy Card รับส่วนลด 10%* <br></br>ต่อที่ 2 ผ่อน
                0%* 3 เดือน (เมื่อใช้บริการ 10,000.- ขึ้นไป){" "}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <br></br>Use Moving services, get Get 2 special privileges{" "}
                <br></br>1 .) Joy Card members receive a 10%* discount <br></br>
                2.) installments 0%* 3 months (when using service 10,000 baht or
                more)
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ผู้เชี่ยวชาญวางแผน การถอดประกอบและขนย้าย
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ถอดประกอบอย่างเชี่ยวชาญ กับเฟอร์นิเจอร์ ทุกแบรนด์*
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                รับประกันความเสียหาย 20% ของค่าบริการ
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                รับประกันคุณภาพงาน 60 วัน
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "14px", md: "16px" } }}
              >
                รายละเอียดบริการ{" "}
                <Link
                  href="/MovingService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none" }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" }, textAlign: "left" }}
              >
                {" "}
                Service information{" "}
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "12px", md: "14px" } }}
              >
                ระยะเวลาโปรโมชั่น : 1 ม.ค. 2566 - 31 มี.ค. 2566
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                Promotion period 1 Jan 2023 - 31 Mar 2023{" "}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                * เงื่อนไขตามที่บริษัทฯกำหนด Conditions as specified by the
                company.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
      break;
    case 2:
      pages = (
        <Grid
          sx={{ flexGrow: { xs: 1, md: 2 } }}
          container
          justifyContent="center"
          spacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6} sx={{ marginLeft: { md: "-70px" } }}>
            <ImageList
              sx={{
                width: { xs: "90%", md: "80%" },
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              <ImageListItem>
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1200x1200px+2022.10.01+Promotion.png"
                  alt="Promotion-2"
                  height="auto"
                />
              </ImageListItem>
            </ImageList>
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  color: "#FDC300",
                  fontSize: { xs: "20px", md: "22px" },
                }}
              >
                บริการตรวจรับบ้านพร้อมดีไซน์เนอร์ <br></br>
                ราคาเดียวทุกขนาดพื้นที่ต่อประเภทอาคาร
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: 700, fontSize: { xs: "16px", md: "18px" } }}
              >
                คอนโดมิเนี่ยม 4,000 * บาท <br></br>ทาวน์เฮาส์ ทาวน์โฮม 6,000 *
                บาท <br></br>บ้านเดี่ยว บ้านแฝด 6,500 * บาท{" "}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <br></br>Home inspection service with designer, One type one
                price Make sure to check before transferring, covering 5
                categories. Decorate your house, complete in one place at Index
                Living Mall
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ตรวจบ้านโดยวิศวกรผู้เชี่ยวชาญเฉพาะด้าน
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                เข้าตรวจ 2 ครั้ง
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                มีรายงานการตรวจสอบเป็นไฟล์เอกสาร
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ให้คำปรึกษาและออกแบบโดยดีไซน์เนอร์
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "14px", md: "16px" } }}
              >
                รายละเอียดบริการ{" "}
                <Link
                  href="/PreHandoverInspectionService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none" }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" }, textAlign: "left" }}
              >
                {" "}
                Service information{" "}
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "12px", md: "14px" } }}
              >
                ระยะเวลาโปรโมชั่น : 1 ม.ค. 2566 - 31 มี.ค. 2566
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                Promotion period 1 Jan 2023 - 31 Mar 2023{" "}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                * เงื่อนไขตามที่บริษัทฯกำหนด Conditions as specified by the
                company.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
      break;
    case 3:
      pages = (
        <Grid
          sx={{ flexGrow: { xs: 1, md: 2 } }}
          container
          justifyContent="center"
          spacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6} sx={{ marginLeft: { md: "-70px" } }}>
            <ImageList
              sx={{
                width: { xs: "90%", md: "80%" },
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              <ImageListItem>
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1200x1200px+2022.10.03+Promotion.png"
                  alt="Promotion-3"
                  height="auto"
                />
              </ImageListItem>
            </ImageList>
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  color: "#FDC300",
                  fontSize: { xs: "20px", md: "22px" },
                }}
              >
                บริการล้างแอร์ฆ่าเชื้อ Premium Package <br></br>ราคาพิเศษ
                ทุกขนาด
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: 700, fontSize: { xs: "16px", md: "18px" } }}
              >
                ชนิดติดผนัง 1,990 * บาท <br></br>ชนิดอื่นๆ 2,990 * บาท
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <br></br>Disinfection of air conditioner service. Premium
                Package special price Wall type 1,990 Bath, Other type 2,990
                Bath
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ฆ่าเชื้อโรคด้วยมัลติเอนไซม์ มาตรฐานโรงพยาบาล
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ล้าง 1 ครั้ง ปกป้องยาวนาน 12 เดือน
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                ป้องกันน้ำรั่ว น้ำล้น ด้วยแพนแท็บเล็ต
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                วัดค่าความสะอาดก่อนและหลังได้ รายงานผลเป็นไฟล์เอกสาร
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "14px", md: "16px" } }}
              >
                รายละเอียดบริการ{" "}
                <Link
                  href="/DisinfectionOfAirConditionerService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none" }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" }, textAlign: "left" }}
              >
                {" "}
                Service information{" "}
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "12px", md: "14px" } }}
              >
                ระยะเวลาโปรโมชั่น : 1 ต.ค. 2565 - 31 ต.ค. 2565
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                Promotion period 1 Oct 2022 - 31 Oct 2022{" "}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                * เงื่อนไขตามที่บริษัทฯกำหนด Conditions as specified by the
                company.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
      break;
    case 4:
      pages = (
        <Grid
          sx={{ flexGrow: { xs: 1, md: 2 } }}
          container
          justifyContent="center"
          spacing={{ xs: 0, md: 2 }}
        >
          <Grid item xs={12} md={6} sx={{ marginLeft: { md: "-70px" } }}>
            <ImageList
              sx={{
                width: { xs: "90%", md: "80%" },
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              <ImageListItem>
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1200x1200px+Banner+Promotion+02.png"
                  alt="Promotion-3"
                  height="auto"
                />
              </ImageListItem>
            </ImageList>
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  color: "#FDC300",
                  fontSize: { xs: "20px", md: "22px" },
                }}
              >
                สมาชิก JOY CARD รับส่วนลด 5%
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontWeight: 700, fontSize: { xs: "16px", md: "18px" } }}
              >
                บริการดูแลบ้านจาก Index Home Service
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                JOY CARD members receive a 5% discount. Home care services from
                Index Home Service
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                บริการตรวจรับบ้านและคอนโด{" "}
                <Link
                  href="/PreHandoverInspectionService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none", fontWeight: 900 }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                บริการทำความสะอาดบ้าน
                <Link
                  href="/BigCleaningService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none", fontWeight: 900 }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                บริการล้างแอร์ฆ่าเชื้อ{" "}
                <Link
                  href="/DisinfectionOfAirConditionerService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none", fontWeight: 900 }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                บริการกำจัดไรฝุ่นที่นอนและโซฟา
                <Link
                  href="/DustMitesRemovalService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none", fontWeight: 900 }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                บริการกำจัดคราบที่นอนและโซฟา
                <Link
                  href="/StainRemovalService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none", fontWeight: 900 }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontSize: { xs: "12px", md: "14px" } }}
              >
                <CheckCircleIcon
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                />{" "}
                บริการเปลี่ยนหนังหุ้มโซฟา{" "}
                <Link
                  href="/GenuineLeatherRepairService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none", fontWeight: 900 }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "14px", md: "16px" } }}
              >
                รายละเอียดบริการ{" "}
                <Link
                  href="/DisinfectionOfAirConditionerService"
                  style={{ color: "#FDC300" }}
                  sx={{ textDecoration: "none" }}
                >
                  {" "}
                  Click
                </Link>
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" }, textAlign: "left" }}
              >
                {" "}
                Service information{" "}
              </Typography>
            </Grid>
            &nbsp;
            <Grid item xs sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 900, fontSize: { xs: "12px", md: "14px" } }}
              >
                ระยะเวลาโปรโมชั่น : 1 ม.ค. 2565 - 31 ธ.ค. 2565
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                Promotion period 1 Jan 2022 - 31 Dec 2022
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ fontSize: { xs: "10px", md: "12px" } }}
              >
                * เงื่อนไขตามที่บริษัทฯกำหนด Conditions as specified by the
                company.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
      break;
  }
  return (
    <Box
      sx={{
        marginTop: "100px",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item sm={12}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 900,
              textAlign: "center",
              fontSize: { xs: "28px", md: "32px" },
            }}
          >
            โปรโมชั่นและสิทธิพิเศษ
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                fontSize: { xs: "14px", md: "18px" },
              }}
            >
              PROMOTION
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Stack
        spacing={4}
        sx={{
          textAlign: "center",
          alignItems: "center",
        }}
      >
        &nbsp;
        {pages}
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={{ textAlign: "right", paddingRight: 2 }}>
            <Link
              href="https://lin.ee/gEsXq0z"
              target="_blank"
              sx={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "#AEAEAE",
                  borderRadius: "90px",
                  color: "white",
                  fontWeight: 900,
                }}
              >
                ADD LINE
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "left", paddingLeft: 2 }}>
            {/* <Link href="https://homeservice-register.indexlivingmall.com/" target="_blank" sx={{ textDecoration: 'none' }}>   <Button variant="contained" sx={{ background: '#AEAEAE', borderRadius: '90px', color: 'white', fontWeight: 900 }}>REGISTER</Button></Link> */}
          </Grid>
        </Grid>
        <Pagination count={4} page={page} onChange={handleChange} />
        &nbsp;
      </Stack>
      {/* <Fab variant="extended" sx={{

            }}>
                <NavigationIcon />
            </Fab> */}
    </Box>
  );
};

export default Promotion;
