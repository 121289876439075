import React, { FC, ReactElement } from 'react'
import { Box, Link, Container, IconButton, MenuItem, Toolbar, Typography, Button, Divider } from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import { routes } from '../page/routes'
import { NavLink, Route } from 'react-router-dom'
import { styled, alpha } from '@mui/material/styles'
import { browserName } from 'react-device-detect'

const Navbar: FC = (): ReactElement => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  let pages

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }))

  let routess = routes
  for (let key in routes) {
    let objectInKey = routes[key]
    if (objectInKey.title === 'notfound' || objectInKey.title === 'Services') {
      delete routes[key]
    }
  }

  for (let key in routess) {
    let objectInKey = routess[key]
    if (
      objectInKey.title === 'notfound' ||
      objectInKey.title === 'Services' ||
      objectInKey.title === 'Promotion' ||
      objectInKey.title === 'Contact'
    ) {
      delete routess[key]
    }
  }

  if (browserName === 'Chrome') {
    pages = (
      <Link href="/">
        <img
          src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Logo+Index+Home+Service+WH.png"
          alt="logo_home_service"
          width={'20%'}
          style={{ marginLeft: -500 }}
        />
      </Link>
    )
  } else if (browserName === 'Safari') {
    pages = (
      <Link href="/">
        <img
          src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Logo+Index+Home+Service+WH.png"
          alt="logo_home_service"
          width={'20%'}
          style={{ marginLeft: -390 }}
        />
      </Link>
    )
  }

  return (
    <Box
      sx={{
        height: 'auto',
        width: '100%',
        flexGrow: 1,
        backgroundColor: 'secondary.main',
        position: 'fixed',
        zIndex: 10,
        justifyContent: 'space-between',
      }}
    >
      &nbsp;
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              textAlign: 'center',
            }}
          >
            {pages}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 2,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <Link href="/">
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Logo+Index+Home+Service+WH.png"
                alt="logo_home_service"
                width={'60%'}
              />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
            <IconButton
              size="large"
              aria-label="menu bar"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: 'secondary.contrastText' }}
            >
              <MenuIcon sx={{ fontSize: '36px' }} />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              PaperProps={{
                style: {
                  width: '100%',

                  paddingLeft: 5,
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {/* {routes.map((page) => (
                                <Link
                                    key={page.key}
                                    component={NavLink}
                                    to={page.path}
                                    color="black"
                                    underline="none"
                                    variant="button"
                                >
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography variant="subtitle2" component="div" textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                </Link>
                            ))} */}

              <Link key="home-route" component={NavLink} to="/Home" color="black" underline="none" variant="button">
                <MenuItem onClick={handleCloseNavMenu} sx={{ fontSize: '24px' }}>
                  <Typography variant="subtitle2" component="div" textAlign="center" sx={{ fontWeight: 900 }}>
                    Services
                  </Typography>
                </MenuItem>
              </Link>
              <Divider />
              <Link
                key="promotion-route"
                component={NavLink}
                to="/Promotion"
                color="black"
                underline="none"
                variant="button"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography variant="subtitle2" component="div" textAlign="center" sx={{ fontWeight: 900 }}>
                    Promotion
                  </Typography>
                </MenuItem>
              </Link>
              <Divider />
              <Link
                key="Contact-route"
                component={NavLink}
                to="/contact"
                color="black"
                underline="none"
                variant="button"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography variant="subtitle2" component="div" textAlign="center" sx={{ fontWeight: 900 }}>
                    Contact
                  </Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginRight: '1rem',
                marginLeft: '-6rem',
              }}
            >
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
              >
                <div>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="text"
                    sx={{ fontSize: '20px', marginLeft: '2rem', color: 'secondary.contrastText' }}
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    SERVICES
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {routess.map(page =>
                      ['Home-Improvement', 'SOLAR-ROOFTOP-Service', 'EV-CHARGER-Service'].includes(page.key) ? (
                        <Link key={page.key} href={page.path} color="black" underline="none" variant="button">
                          <MenuItem onClick={handleCloseNavMenu} disableRipple>
                            <Typography variant="subtitle2" component="div" textAlign="center">
                              {page.title}
                            </Typography>
                          </MenuItem>
                        </Link>
                      ) : (
                        <Link
                          key={page.key}
                          component={NavLink}
                          to={page.path}
                          color="black"
                          underline="none"
                          variant="button"
                        >
                          <MenuItem onClick={handleCloseNavMenu} disableRipple>
                            <Typography variant="subtitle2" component="div" textAlign="center">
                              {page.title}
                            </Typography>
                          </MenuItem>
                        </Link>
                      ),
                    )}
                  </StyledMenu>
                </div>
                <Link href="/Promotion">
                  <Button sx={{ fontSize: '20px', marginLeft: '2rem', color: 'secondary.contrastText' }}>
                    PROMOTION
                  </Button>
                </Link>
                <Link
                  key="contect-route"
                  component={NavLink}
                  to="/contact"
                  color="black"
                  underline="none"
                  variant="button"
                >
                  <Button sx={{ fontSize: '20px', marginLeft: '2rem', color: 'secondary.contrastText' }}>
                    CONTACT
                  </Button>
                </Link>
              </Typography>
            </Box>
          </Box>
        </Toolbar>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ borderTop: '3px solid #FDC300', margin: 'auto -25px' }}
        ></Typography>
      </Container>
    </Box>
  )
}

export default Navbar
