import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { routes as appRoutes } from './page/routes'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import React, { useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import TagManager from 'react-gtm-module'
function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-NFVWJ3M',
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#181716',
      },
      secondary: {
        main: '#181716',
        light: '#82e9de',
        dark: '#00867d',
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontFamily: ['Kanit', 'sans-serif'].join(','),
      fontSize: 14,
    },
  })

  useEffect(() => {
    const data = getCookieConsentValue('homeservice')
    if (data === 'true') {
      TagManager.initialize(tagManagerArgs)
    } else {
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Box height="auto" width="auto" display="flex" flexDirection="column">
        <Router>
          <Navbar />
          <CssBaseline />
          <React.StrictMode>
            <Routes>
              {appRoutes.map(route => (
                <Route key={route.key} path={route.path} element={route.component && <route.component />} />
              ))}
            </Routes>
          </React.StrictMode>
          {
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              // declineButtonText="decline"
              // enableDeclineButton
              cookieName="homeservice"
              cookieValue="true"
              style={{ background: '#181716 ', borderRadius: '4px' }}
              buttonStyle={{ color: '#4e503b', fontSize: '13px', borderRadius: '4px', background: '#fdc300' }}
              declineButtonStyle={{ fontSize: '13px', borderRadius: '4px', background: '#ae1010' }}
              expires={365}
              onDecline={() => {
                // let data = Cookies.get()
                // for (const [key, value] of Object.entries(data)) {
                //   Cookies.remove(key);
                // }
              }}
              onAccept={accepted => {
                TagManager.initialize(tagManagerArgs)
              }}
            >
              <span style={{ fontFamily: 'kanit', fontSize: '12pt' }}>
                เว็บไซต์ Index Home Service ใช้คุกกี้เพื่อประสบการณ์การใช้งานที่ดี การคลิกปุ่มยอมรับ
                หรือใช้งานเว็บไซต์ของเรา ถือเป็นการยอมรับนโยบายการใช้งานคุกกี้.{' '}
              </span>

              <span
                style={{
                  textDecorationLine: 'underline',
                  fontSize: '12pt',
                  fontWeight: 500,
                  fontFamily: 'kanit',
                  textDecoration: 'none',
                }}
              >
                <a
                  href="https://www.indexlivingmall.com/cookie-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  นโยบายการใช้งานคุกกี้
                </a>
              </span>
            </CookieConsent>
          }
        </Router>
      </Box>
      <Footer />
    </ThemeProvider>
  )
}

export default App
