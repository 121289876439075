import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography, Link, } from "@mui/material";

export const Footer: FC = (): ReactElement => {
    return (
        <Box
            sx={{
                width: "auto",
                flexGrow: 1,
                height: "auto",
                backgroundColor: "secondary.contrastText",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                background: "#e5e5e5"
            }}
        >
            <Container fixed>
                <Grid container direction="column" alignItems="let" spacing={0}>

                    <Grid item xs={4}>
                        <Typography color="black" sx={{ fontWeight: 900, fontSize: { xs: "30px", md: "40px" } }} >
                            Call Center&nbsp;&nbsp;1379  กด  2
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ fontSize: { xs: "9px", md: "12px" } }}>
                        วันทำการ : วันจันทร์ - วันอาทิตย์  เวลา 9.00 - 18.00 น. (ยกเว้นวันหยุดนักขัตฤกษ์)
                    </Grid>
                    <Grid item xs={4} sx={{ fontSize: { xs: "12px", md: "16px" } }}>
                        <Link href="https://www.indexlivingmall.com/privacy-policy" target="_blank" sx={{ textDecoration: 'none' }}><span style={{ fontWeight: 900 }}>Privacy Policy</span></Link>{'  '}
                        © Index Living Mall PLC.
                    </Grid>
                    <Grid item xs={4} sx={{ paddingTop: '12px' }}>
                        <Link href="https://www.facebook.com/indexhomeservice/" target="_blank"><img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/FB.png" alt="FB" width="40px" height="40px" /></Link>
                        &nbsp;
                        <Link href="https://lin.ee/gEsXq0z" target="_blank"><img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/LINE.png" alt="line-brands1" width="40px" height="40px" /></Link>
                    </Grid>
                </Grid>


            </Container>
        </Box >
    );
};

export default Footer;