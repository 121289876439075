import React, { ReactElement, FC } from "react";
import { Box, Typography, Grid, Button, ImageList, ImageListItem, Link } from "@mui/material";
import { routes } from "../routes";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const Home: FC<any> = (): ReactElement => {


    const Styles = {
        Buttonline: { fontSize: { md: '18px' }, position: { md: "absolute" }, left: { xs: '8%', md: '37%' }, right: { xs: '20%', md: '37%' }, textAlign: 'center', background: '#FDC300', borderRadius: '90px', color: 'white', fontWeight: 900, },
        TypographyHeader: { fontSize: { xs: '30px', md: '60px' }, fontWeight: '900' },
        Buttonlinkleft: { position: 'absolute', background: '#AEAEAE', left: { xs: '10%', md: '10%' }, right: { xs: '10%', md: '53%' }, borderRadius: '90px', color: 'white' },
        Buttonlinkright: { position: 'absolute', background: '#AEAEAE', left: { xs: '10%', md: '53%' }, right: { xs: '10%', md: '10%' }, borderRadius: '90px', color: 'white' },
        Textdetail: { fontWeight: 900, textAlign: 'center', fontSize: '20px' }
    };



    for (let key in routes) {
        let objectInKey = routes[key];
        if (objectInKey.title === 'notfound' || objectInKey.title === 'Services' || objectInKey.title === 'Promotion' || objectInKey.title === 'Contact') {
            delete routes[key]
        }
    }

    return (
        <Box sx={{
            marginTop: "80px",

        }}>
            <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 10, md: 6 }}>
                <Grid item xs={12} md={12} sx={{ margin: '-20px' }} >
                    <Slide
                        autoplay={true}
                        slidesToShow={1}
                        indicators={true}
                    >
                        <div className="each-slide-effect" >
                            <Link href="/Promotion?number=2" >
                                <ImageList sx={{ width: { xs: '200%' }, display: { xs: "", md: "none" } }}>
                                    <ImageListItem>
                                        <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1440x800px+Banner+Slide+01.png" alt="Rectangle7" />
                                    </ImageListItem>
                                </ImageList>
                                <ImageList sx={{ width: { md: '100%' }, display: { xs: "none", md: "block" } }}>
                                    <ImageListItem>
                                        <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1440x552px+Banner+Slide+01.png" alt="Rectangle7" />
                                    </ImageListItem>
                                </ImageList>

                            </Link>
                        </div>
                        <div className="each-slide-effect">
                            <Link href="/Promotion?number=4">
                                <ImageList sx={{ width: { xs: '200%' }, display: { xs: "", md: "none" } }}>
                                    <ImageListItem>
                                        <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1440x800px+Banner+Slide+02.png" alt="Rectangle7" />
                                    </ImageListItem>
                                </ImageList>

                                <ImageList sx={{ width: { md: '100%' }, display: { xs: "none", md: "block" } }}>
                                    <ImageListItem>
                                        <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1440x552px+Banner+Slide+02.png" alt="Rectangle7" />
                                    </ImageListItem>
                                </ImageList>
                            </Link>
                        </div>
                        <div className="each-slide-effect">
                            <Link href="/Promotion?number=1">
                                <ImageList sx={{ width: { xs: '200%' }, display: { xs: "", md: "none" } }}>
                                    <ImageListItem>
                                        <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1440x800px+Banner+Slide+03.png" alt="Rectangle7" />
                                    </ImageListItem>
                                </ImageList>

                                <ImageList sx={{ width: { md: '100%' }, display: { xs: "none", md: "block" } }}>
                                    <ImageListItem>
                                        <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/1440x552px+Banner+Slide+03.png" alt="Rectangle7" />
                                    </ImageListItem>
                                </ImageList>
                            </Link>
                        </div>
                    </Slide>
                </Grid>

                <Grid item xs={8} sx={{ margin: { xs: '20px' } }} >
                    <Button variant="contained" sx={Styles.Buttonline} href="https://lin.ee/gEsXq0z" target="_blank" ><img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Vector.png" width="23" height="23" alt="Vector"></img>&nbsp;ADD LINE สอบถามบริการ</Button>
                </Grid>
                <Grid item xs={14} sx={{ margin: { md: '20px' } }}>
                    <Typography variant="h6" align="center" sx={Styles.TypographyHeader}>“ เรื่องดูแลบ้าน เราช่วยคุณได้ ” </Typography>
                </Grid>
            </Grid>
            &nbsp;

            <Grid container justifyContent={'center'} rowSpacing={{ xs: 10, md: 12 }} columnSpacing={{ xs: 10, sm: 10, md: 3 }}>
                {routes.map((page, Index) => (
                    <Grid item xs={8} sm={10} md={6} sx={{ textAlign: 'center' }}>
                        {/* <Button variant="contained" sx={Styles.Buttonlink} href={page.path}><Typography variant="h6" sx={{ fontWeight: 900, fontSize: '16px', textAlign: 'center' }}>{page.name} <Typography variant="subtitle2" component="div" sx={{ fontWeight: 300, fontSize: '12px' }}>{page.title}</Typography></Typography>
                        </Button> */}
                        {Index % 2 ? <Button variant="contained" sx={Styles.Buttonlinkright} href={page.path}><Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '16px', md: '20px' }, textAlign: 'center' }}>{page.name} <Typography variant="subtitle2" component="div" sx={{ fontWeight: 300, fontSize: '12px' }}>{page.title}</Typography></Typography>
                        </Button> : <Button variant="contained" sx={Styles.Buttonlinkleft} href={page.path}><Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '16px', md: '20px' }, textAlign: 'center' }}>{page.name} <Typography variant="subtitle2" component="div" sx={{ fontWeight: 300, fontSize: '12px' }}>{page.title}</Typography></Typography>
                        </Button>}

                        {/* <Item>1</Item> */}
                    </Grid>
                ))}
                <Grid item xs={10} sm={10} md={10}>
                    <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 900, fontSize: { xs: '28px', md: '50px' } }}>Why Index Home Service ?</Typography>
                </Grid>
            </Grid>

            <Grid sx={{ flexGrow: { xs: 1, md: 2 } }} container justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 2 }}>
                <Grid item xs={10} md={2}>
                    <ImageList sx={{ width: { xs: '80%', md: '100%' }, marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                        <ImageListItem>
                            <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/282x282px+Quality.png" alt="Rectangle7" />
                        </ImageListItem>
                    </ImageList>
                    <Typography variant="h6" sx={Styles.Textdetail}>ผลงานคุณภาพ<Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '12px', md: '16px' } }}>Quality</Typography></Typography>
                </Grid>            
                <Grid item xs={10} md={2}>
                    <ImageList sx={{ width: { xs: '80%', md: '100%' }, marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                        <ImageListItem>
                            <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/282x282px+Professtional.png" alt="Rectangle8" width={'80%'} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                        </ImageListItem>
                    </ImageList>
                    <Typography variant="h6" sx={Styles.Textdetail}>ทีมงานมืออาชีพ<Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '12px', md: '16px' } }}>Professional</Typography></Typography>
                </Grid>
                <Grid item xs={10} md={2}>
                    <ImageList sx={{ width: { xs: '80%', md: '100%' }, marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                        <ImageListItem>
                            <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/282x282px+One+Stop+Service.png" alt="Rectangle9" width={'80%'} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                        </ImageListItem>
                    </ImageList>
                    <Typography variant="h6" sx={Styles.Textdetail}>บริการครบสะดวกจริง<Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '12px', md: '16px' } }}>One Stop Service</Typography></Typography>
                </Grid>
                <Grid item xs={10} md={2}>
                    <ImageList sx={{ width: { xs: '80%', md: '100%' }, marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                        <ImageListItem>
                            <img src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/282x282px+Warranty.png" alt="Rectangle10" width={'80%'} height="auto" style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                        </ImageListItem>
                    </ImageList>
                    <Typography variant="h6" sx={Styles.Textdetail}>รับประกันคุณภาพ<Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '12px', md: '16px' } }} > Warranty</Typography></Typography>
                </Grid>

            </Grid>
            &nbsp;
        </Box>
    );
};

export default Home;






