import React, { ReactElement, FC } from 'react'
import {
  Box,
  Typography,
  Grid,
  Button,
  Tab,
  Tabs,
  Link,
  ImageList,
  ImageListItem,
  Card,
  CircularProgress,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useGetBigCleaningServiceQuery } from '../../generated/graphql-types'
import _ from 'lodash'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const BigCleningService: FC<any> = (): ReactElement => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  const replaceWithBr = (text: String) => {
    return text.replace(/\n/g, '<br />')
  }

  const Styles = {
    Buttonline: {
      position: { md: 'absolute' },
      fontSize: { md: '18px' },
      left: { md: '20%' },
      right: { md: '60%' },
      fontWeight: 900,
      background: '#FDC300',
      borderRadius: '90px',
      color: 'white',
    },
    textheader: { fontWeight: 900, textAlign: 'left', fontSize: { xs: '20px', md: '22px' } },
    text: { textAlign: 'left', fontSize: { xs: '12px', md: '14px' } },
    textnumber: { fontWeight: 900, fontSize: '96px', textAlign: 'center', color: '#FDC300' },
    headerpage: { fontWeight: 900, textAlign: 'center', fontSize: { xs: '24px', md: '26px' } },
  }

  const { loading, data, error } = useGetBigCleaningServiceQuery()
  if (loading)
    return (
      <Box
        sx={{
          marginTop: '100px',
          height: 'calc(100vh - 100px)',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )
  if (error) return <p>Error :(</p>

  const services = data?.services?.data?.[0]?.attributes
  const feature = services?.feature
  const process_content = services?.process
  const term = services?.term

  return (
    <Box
      sx={{
        marginTop: '100px',
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item sm={8}>
          <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '32px' }, textAlign: 'center' }}>
            {services?.title}
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ fontWeight: 700, textAlign: 'center', marginBottom: '-10px', fontSize: { xs: '14px', md: '18px' } }}
          >
            {services?.subtitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageList
            sx={{
              width: { xs: '90%', md: '60%' },
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              borderRadius: '18px',
            }}
          >
            <ImageListItem>
              <img src={services?.cover.data?.attributes?.url} alt="Cover-2" />
            </ImageListItem>
          </ImageList>
        </Grid>
      </Grid>
      &nbsp;
      <Grid container spacing={4} sx={{ paddingRight: { xs: 4 } }}>
        <Grid item xs={6} md={6} sx={{ textAlign: 'center' }}>
          {services?.line_url && (
            <Button variant="contained" sx={Styles.Buttonline} href={`${services?.line_url}`} target="_blank">
              <img
                src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Vector.png"
                width="23"
                height="23"
                alt="Vector"
              ></img>
              &nbsp;ADD LINE
            </Button>
          )}
        </Grid>
        <Grid item xs={2} md={1.5} sx={{ textAlign: 'right' }}>
          {services?.shop_url && (
            <Typography variant="subtitle2" component="div">
              <Link href={`${services?.shop_url}`} target="_blank">
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Shop+icon.png"
                  alt="shopicon"
                  width="36px"
                  height="36px"
                />
              </Link>
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} md={1} sx={{ textAlign: 'right' }}>
          {services?.youtube_url && (
            <Typography variant="subtitle2" component="div">
              <Link href={`${services?.youtube_url}`} target="_blank">
                {' '}
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Youtube+btn.png"
                  alt="youtube"
                  width="36px"
                  height="36px"
                />
              </Link>
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} md={1} sx={{ textAlign: 'right' }}>
          {services?.review_url && (
            <Typography variant="subtitle2" component="div">
              <Link href={`${services?.review_url}`} target="_blank">
                {' '}
                <img
                  src="https://home-service-test.s3.ap-southeast-1.amazonaws.com/Review+icon.png"
                  alt="Reviewicon"
                  width="36px"
                  height="36px"
                />
              </Link>
            </Typography>
          )}
        </Grid>
      </Grid>
      &nbsp;
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ background: '#f2f2f2' }}>
            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', background: 'white' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
              >
                <Tab label="Features" {...a11yProps(0)} sx={{ fontWeight: 900, fontSize: { md: '18px' } }} />
                <Tab label="Process" {...a11yProps(1)} sx={{ fontWeight: 900, fontSize: { md: '18px' } }} />
                <Tab label="Price" {...a11yProps(2)} sx={{ fontWeight: 900, fontSize: { md: '18px' } }} />
                <Tab label="Terms" {...a11yProps(3)} sx={{ fontWeight: 900, fontSize: { md: '18px' } }} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid
                sx={{ flexGrow: { xs: 1, md: 2 } }}
                container
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 0, md: 3 }}
              >
                {feature?.map((j, index) => (
                  <Grid item xs={12} md={5} key={index}>
                    <Grid item xs>
                      <ImageList
                        sx={{
                          width: { xs: '60%', md: '70%' },
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          borderRadius: '18px',
                        }}
                      >
                        <ImageListItem>
                          <img src={j?.icon?.data?.attributes?.url} alt="Rectangle7-2" />
                        </ImageListItem>
                      </ImageList>{' '}
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 900, textAlign: 'center', fontSize: { xs: '20px', md: '22px' } }}
                        dangerouslySetInnerHTML={{
                          __html: `${j?.title}`,
                        }}
                      ></Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, fontSize: { xs: '12px', md: '14px' }, textAlign: 'center' }}
                        dangerouslySetInnerHTML={{
                          __html: `${j?.subtitle}`,
                        }}
                      ></Typography>
                    </Grid>
                    <Grid item xs sx={{ height: '100px', textAlign: 'top' }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: { xs: '12px', md: '14px' }, textAlign: 'center' }}
                        dangerouslySetInnerHTML={{
                          __html: `${j?.description}`,
                        }}
                      ></Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid
                sx={{ flexGrow: { xs: 1, md: 2 } }}
                container
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 0, md: 3 }}
              >
                <Grid item sm={12}>
                  <Typography variant="h6" sx={Styles.headerpage}>
                    ขั้นตอนการให้บริการ
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ fontWeight: 700, fontSize: { xs: '14px', md: '16px' }, textAlign: 'center' }}
                  >
                    Service Process{' '}
                  </Typography>
                </Grid>
                &nbsp;
                {_.sortBy(process_content, ['no'])?.map((j, index) => (
                  <Grid
                    sx={{ flexGrow: { xs: 1, md: 2 } }}
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={{ xs: 0, md: 3 }}
                    key={index}
                  >
                    <Grid item xs={12} md={4}>
                      <ImageList
                        sx={{
                          width: { xs: '90%', md: '100%' },
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          borderRadius: '18px',
                        }}
                      >
                        <ImageListItem>
                          <img src={j?.image?.data?.attributes?.url} alt="Rectangle7-2" />
                        </ImageListItem>
                      </ImageList>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                      <Typography variant="subtitle2" component="div" sx={Styles.textnumber}>
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4} md={4}>
                      {j?.content?.map((k, index) => (
                        <>
                          {index != 0 && <>&nbsp;</>}
                          <Typography variant="h6" sx={Styles.textheader}>
                            {k?.title}
                          </Typography>
                          <Typography variant="subtitle2" component="div" sx={Styles.text}>
                            {k?.description}
                          </Typography>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 3, md: 4 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item sm={12}>
                  <Typography variant="h6" sx={Styles.headerpage}>
                    อัตราค่าบริการ
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ fontWeight: 700, textAlign: 'center', fontSize: { xs: '14px', md: '16px' } }}
                    >
                      Service Charge{' '}
                    </Typography>
                  </Typography>
                </Grid>
                {/* <Grid item sm={12}>
                                    <TableContainer component={Paper} sx={{ borderRadius: '18px' }}>
                                        <Table sx={{ minWidth: 250, Width: '100%', background: '#F2F2F2' }} >
                                            <TableHead>
                                                <TableRow sx={{ border: '1px solid #D9D9D9', background: '#aeaeae' }}>
                                                    <TableCell sx={{ minWidth: 150 }}><Typography variant="h5" sx={{ fontWeight: 900, fontSize: '20px', color: 'white', textAlign: 'center' }}>พื้นที่อาคาร</Typography><Typography variant="h5" sx={{ fontSize: '12px', color: 'white', textAlign: 'center' }}>Area</Typography></TableCell>
                                                    <TableCell sx={{ minWidth: 140 }}><Typography variant="h5" sx={{ fontWeight: 900, fontSize: '20px', color: 'white', textAlign: 'center' }}>ค่าบริการ(฿)</Typography><Typography variant="h5" sx={{ fontSize: '12px', color: 'white', textAlign: 'center' }}>Price</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody >

                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #D9D9D9' } }}
                                                >
                                                    <TableCell component="th" align="center" scope="row" sx={{ border: '1px solid #D9D9D9' }}>

                                                        <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '18px', md: '20px' } }}>30 - 69
                                                            <Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '10px', md: '12px' } }} >sq m.</Typography>
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' } }}>3,750</TableCell>
                                                </TableRow>

                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #D9D9D9' } }}
                                                >
                                                    <TableCell component="th" align="center" scope="row" sx={{ border: '1px solid #D9D9D9' }}>

                                                        <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '18px', md: '20px' } }}>70 - 99
                                                            <Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '10px', md: '12px' } }}>sq m.</Typography>
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' } }}>5,650</TableCell>
                                                </TableRow>


                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #D9D9D9' } }}
                                                >
                                                    <TableCell component="th" align="center" scope="row" sx={{ border: '1px solid #D9D9D9' }}>

                                                        <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '18px', md: '20px' } }}>100 - 150
                                                            <Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '10px', md: '12px' } }}>sq m.</Typography>
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' } }}>7,500</TableCell>
                                                </TableRow>


                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #D9D9D9' } }}
                                                >
                                                    <TableCell component="th" align="center" scope="row" sx={{ border: '1px solid #D9D9D9' }}>

                                                        <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '18px', md: '20px' } }}>151 - 200
                                                            <Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '10px', md: '12px' } }}>sq m.</Typography>
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' } }}>9,000</TableCell>
                                                </TableRow>

                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #D9D9D9' } }}
                                                >
                                                    <TableCell component="th" align="center" scope="row" sx={{ border: '1px solid #D9D9D9' }}>

                                                        <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '18px', md: '20px' } }}>201 - 250
                                                            <Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '10px', md: '12px' } }}>sq m.</Typography>
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' } }}>11,000</TableCell>
                                                </TableRow>

                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #D9D9D9' } }}
                                                >
                                                    <TableCell component="th" align="center" scope="row" sx={{ border: '1px solid #D9D9D9' }}>

                                                        <Typography variant="h6" sx={{ fontWeight: 900, fontSize: { xs: '18px', md: '20px' } }}>251 - 300
                                                            <Typography variant="subtitle2" component="div" sx={{ fontSize: { xs: '10px', md: '12px' } }}>sq m.</Typography>
                                                        </Typography>


                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' } }}>12,500</TableCell>
                                                </TableRow>

                                                <TableCell component="th" scope="row" colSpan={2}>
                                                    <Typography variant="h5" sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>* ความสูงจากพื้นถึงฝ้าเพดานไม่เกิน 2.5 m. หากความสูงเกินกว่ากำหนด จะมีค่าบริการในพื้นที่สูงเพิ่มตามที่เจ้าหน้าที่ประเมินราคา <br></br>The height from floor to ceiling does not exceed 2.5 m. In case the height exceeds the limit. There will be an additional service fee according to the appraiser.</Typography>
                                                </TableCell>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid> */}
                <Grid item sm={12}>
                  <TableContainer component={Paper} sx={{ borderRadius: '18px' }}>
                    <Table sx={{ minWidth: 250, Width: '100%', background: '#F2F2F2' }}>
                      <TableHead></TableHead>
                      <TableBody>
                        <TableRow sx={{ background: '#E5E5E5' }}>
                          <TableCell>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: 900, fontSize: { xs: '16px', md: '18px' }, textAlign: 'center' }}
                            >
                              ราคาเริ่มต้น
                            </Typography>
                            <Typography variant="h5" sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>
                              {' '}
                              Starting price
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: 900, fontSize: { xs: '28px', md: '30px' }, textAlign: 'center' }}
                            >
                              8,500
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ fontSize: { xs: '12px', md: '14px' }, textAlign: 'center' }}>
                            บาท{' '}
                            <Typography variant="h5" sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>
                              {' '}
                              Bath
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: 900, fontSize: { xs: '12px', md: '14px' }, textAlign: 'center' }}
                            >
                              ติดต่อเจ้าหน้าที่ทาง LINE Official Account ID : @indexservice เพื่อประเมินราคา
                            </Typography>
                            <Typography variant="h5" sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>
                              {' '}
                              Contact staff via LINE Official Account ID : @indexservice{' '}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={{ xs: 3, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={10}>
                  <Typography variant="h6" sx={Styles.headerpage}>
                    เงื่อนไขการให้บริการ
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ fontWeight: 700, fontSize: { xs: '14px', md: '16px' }, textAlign: 'center' }}
                  >
                    Terms Of Service{' '}
                  </Typography>
                </Grid>
                <Grid item xs={10} sx={{ marginLeft: { md: '120px' } }}>
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontSize: { xs: '12px', md: '14px' } }}
                    dangerouslySetInnerHTML={{
                      __html: replaceWithBr(`${term?.description}`),
                    }}
                  ></Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BigCleningService
