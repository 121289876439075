import { FC } from 'react'
import Home from './home/Home'
import PreHandoverInspectionService from './PreHandoverInspectionService/PreHandoverInspectionService'
import moveService from './moveService/moveService'
import BigCleningService from './BigCleningService/BigCleningService'
import DisinfectionOfAirConditionerService from './DisinfectionOfAirConditionerService/DisinfectionOfAirConditionerService'
import DustMitesRempvalService from './DustMitesRempvalService/DustMitesRempvalService'
import StainRemovalService from './StainRemovalService/StainRemovalService'
import WallPartitionService from './WallPartitionService/WallPartitionService'
import SidingDoorService from './SidingDoorService/SidingDoorService'
import GarageExtensionService from './GarageExtensionService/GarageExtensionService'
import GenuineLeatherRepairService from './GenuineLeatherRepairService/GenuineLeatherRepairService'
import Contact from './Contact/Contact'
import Promotion from './Promotion/Promotion'
interface Route {
  key: string
  title: string
  name: string
  path: string
  enabled: boolean
  component?: FC<{}>
}

export const routes: Array<Route> = [
  {
    key: 'not-found',
    title: 'notfound',
    name: 'หาไม่เจอ',
    path: '*',
    enabled: true,
    component: Home,
  },
  {
    key: 'home-route',
    title: 'Services',
    name: 'หน้าแรก',
    path: '/',
    enabled: true,
    component: Home,
  },
  {
    key: 'Pre-Handover-Inspection-Service-route',
    title: 'Pre-Handover Inspection Service',
    name: 'ตรวจรับบ้านและคอนโด',
    path: '/PreHandoverInspectionService',
    enabled: true,
    component: PreHandoverInspectionService,
  },
  {
    key: 'Moving-Service-route',
    title: 'Moving Service',
    name: 'ขนย้าย',
    path: '/MovingService',
    enabled: true,
    component: moveService,
  },
  {
    key: 'Big-Cleaning-Service-route',
    title: 'Big Cleaning Service',
    name: 'ทำความสะอาดบ้าน',
    path: '/BigCleaningService',
    enabled: true,
    component: BigCleningService,
  },
  {
    key: 'Disinfection-Of-Air-Conditioner-Service-route',
    title: 'Disinfection Of Air Conditioner Service',
    name: 'ล้างแอร์ฆ่าเชื้อ',
    path: '/DisinfectionOfAirConditionerService',
    enabled: true,
    component: DisinfectionOfAirConditionerService,
  },
  // {
  //   key: 'Dust-Mites-Removal-Service-route',
  //   title: 'Dust Mites Removal Service',
  //   name: 'กำจัดไรฝุ่นที่นอนและโซฟา',
  //   path: '/DustMitesRemovalService',
  //   enabled: true,
  //   component: DustMitesRempvalService
  // },
  // {
  //   key: 'Stain-Removal-Service-route',
  //   title: 'Stain Removal Service',
  //   name: 'กำจัดคราบที่นอนและโซฟา',
  //   path: '/StainRemovalService',
  //   enabled: true,
  //   component: StainRemovalService
  // },
  // {
  //   key: 'Wall-Partition-Service-route',
  //   title: 'Wall Partition Service',
  //   name: 'ติดตั้งผนังเบา',
  //   path: '/WallPartitionService',
  //   enabled: true,
  //   component: WallPartitionService
  // },
  // {
  //   key: 'Sliding-Door-Service-route',
  //   title: 'Sliding Door Service',
  //   name: 'ติดตั้งบานเลื่อนอลูมิเนียม',
  //   path: '/SlidingDoorService',
  //   enabled: true,
  //   component: SidingDoorService
  // },
  // {
  //   key: 'Garage-Extension-Service-route',
  //   title: 'Garage Extension Service',
  //   name: 'ติดตั้งหลังคาโรงจอดรถและกันสาด',
  //   path: '/GarageExtensionService',
  //   enabled: true,
  //   component: GarageExtensionService
  // },
  {
    key: 'Genuine-Leather-Repair-Service-route',
    title: 'Genuine Leather Repair Service',
    name: 'เปลี่ยนหนังหุ้มโซฟา',
    path: '/GenuineLeatherRepairService',
    enabled: true,
    component: GenuineLeatherRepairService,
  },
  {
    key: 'SOLAR-ROOFTOP-Service',
    title: 'Solar Rooftop Service',
    name: 'ระบบโซลาร์เซลล์',
    path: 'https://sites.google.com/view/homeservice-indexlivingmall/solar-rooftop',
    enabled: true,
    component: undefined,
  },
  {
    key: 'EV-CHARGER-Service',
    title: 'EV Charger Service',
    name: ' เครื่องชาร์จรถยนต์ไฟฟ้า',
    path: 'https://sites.google.com/view/homeservice-indexlivingmall/ev-charger',
    enabled: true,
    component: undefined,
  },
  {
    key: 'Home-Improvement',
    title: 'Home Improvement',
    name: 'ตกแต่งปรับปรุงอาคาร',
    path: 'https://sites.google.com/view/homeservice-indexlivingmall/home-improvement',
    enabled: true,
    component: undefined,
  },
  {
    key: 'Promotion-route',
    title: 'Promotion',
    name: 'โปรโมชั่น',
    path: '/Promotion',
    enabled: true,
    component: Promotion,
  },
  {
    key: 'Contact-route',
    title: 'Contact',
    name: 'ติดต่อ',
    path: '/contact',
    enabled: true,
    component: Contact,
  },
]
